<template>
  <Modal v-model="showModal" title="编辑单位" :mask-closable="false" width="900px">
    <div class="edit-container">
      <Form ref="form" :model="form" :rules="rules" :label-width="80" class="form-area">
        <!-- <FormItem label="单位类型">
        <div style="display: flex; align-items: center;">
          <Checkbox v-model="form.isManage">管理单位</Checkbox>
          <Checkbox v-model="form.isMaintance">管养单位</Checkbox>
        </div>
      </FormItem> -->
        <FormItem prop="name" label="名称">
          <Input ref="name" type="text" v-model="form.name" placeholder="请输入名称"></Input>
        </FormItem>
        <FormItem label="联系人">
          <Input ref="linkman" type="text" v-model="form.linkman" placeholder="请输入名称"></Input>
        </FormItem>
        <FormItem label="联系方式">
          <Input ref="mobile" type="text" v-model="form.mobile" placeholder="请输入名称"></Input>
        </FormItem>
        <FormItem :label-width="0">
          <vxe-table ref="userTable" border  show-overflow show-header-overflow :data="users" :height="425" :row-config="{isHover: true}" :checkbox-config="{checkRowKeys: checkedKeys, highlight: true}">
            <vxe-column width="50" type="checkbox" field="id" fixed="left"></vxe-column>
            <!-- <vxe-column width="80" title="组织类型">
              <template #default="params">
                {{params.row.self ? '本级组织' : '上级组织'}}
              </template>
            </vxe-column> -->
            <vxe-column field="code" title="登录名" width="150"></vxe-column>
            <vxe-column field="name" title="姓名" width="150"></vxe-column>
            <vxe-column field="mobile" title="联系方式"></vxe-column>
          </vxe-table>
        </FormItem>
      </Form>
      <div class="tree-area">
        <div class="tree-header">
          站点管理范围：
        </div>
        <div class="tree-content">
          <Tree ref="groupTree" :data="groups" show-checkbox check-strictly @on-check-change="noceCheckChanged"></Tree>
        </div>
      </div>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'ModalDefineEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
    forms: {
      type: Array,
      default() { return []; }
    }
  },
  data() {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      checkedKeys: [],
      form: {
        id: 0,
        name: '',
        linkman: '',
        mobile: '',
        groups: [],
        users: [],
      },
      rules: {
        name: { required: true, type: 'string', min: 1, max: 50, message: '名称长度必须 1 - 50 个字符', trigger: 'blur' },
        formId: { required: true, type: 'number', min: 1, max: 50, message: '必须选择关联表单', trigger: 'on-change' },
      },
      allGroups: [],
      groups: [],
      users: [],
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (newVal) {
        // console.log('edit define', this.item)
        this.isAdd = this.item.isAdd;
        if (this.isAdd) {
          this.form.id = 0;
          this.form.name = '';
          this.form.linkman = '';
          this.form.mobile = '';
          this.form.groups = [];
          this.form.users = [];
        } else {
          this.form.id = this.item.data.id;
          this.form.name = this.item.data.name;
          this.form.linkman = this.item.data.linkman;
          this.form.mobile = this.item.data.mobile;
          this.form.groups = this.item.data.groups;
          this.form.users = this.item.data.users;
        }
        this.getGroups();
        this.getUsers();
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    }
  },
  mounted: function () {
  },
  methods: {
    noceCheckChanged: function (nodes, node) {
      // console.log('group tree node checked', node)
      if (!node.checked) return;
      this.unchecksubs(node);
      for (let n of nodes) {
        if (this.isSub(node, n)) {
          this.$set(n, 'checked', false);
        }
      }
    },
    isSub: function (target, source) {
      if (target.id == source.id) return false;
      let issub = false;
      let pid = target.parentId;
      let running = true;
      while (running) {
        let pnodes = this.allGroups.filter(p => p.id == pid);
        if (pnodes.length == 0) break;
        let pnode = pnodes[0];
        if (pnode.id == source.id) {
          issub = true;
          break;
        } else {
          pid = pnode.parentId;
        }
      }
      return issub;
    },
    unchecksubs: function (node) {
      if (!node.children) return;
      for (let n of node.children) {
        // n.checked = false;
        this.$set(n, 'checked', false);
        this.unchecksubs(n);
      }
    },
    getUsers: function () {
      this.$axios.post(`sys/auth/QueryCustUser`, {}).then(res => {
        let uuus = res.data.map(p => { return { id: p.id, code: p.code, name: p.name, mobile: p.mobile, self: p.self }; });
        this.$set(this, 'users', uuus);
        // this.checkedKeys = this.form.users;
        var us = this.users.filter(p => this.form.users.indexOf(p.id) != -1);
        this.$refs.userTable.clearCheckboxRow()
        setTimeout(() => {
          this.$refs.userTable.setCheckboxRow(us, true);
        }, 200);
        // this.$refs.userTable.setCheckboxRow([this.users[2], this.users[3]], true).then(aaa => {
        //   let userNodes = this.$refs.userTable.getCheckboxRecords();
        //   console.log('select result', aaa, userNodes);
        // });
      })
    },
    getGroups: function () {
      this.$axios.post(`sys/auth/QueryGroupOld`, {}).then(res => {
        this.$set(this, 'allGroups', res.data.groups);
        if (res.data.roots.length == 0) {
          this.$set(this, 'groups', []);
        } else {
          let grpTree = [];
          for (let r of res.data.roots) {
            let grps = res.data.groups.filter(p => p.id == r);
            if (grps.length == 0) continue;
            let grp = grps[0];
            // let node = Object.assign(grp[0], {expand: true, children: [], title: grp[0].name, checked: this.form.groups.indexOf(grp[0].id) != -1});
            let node = { id: grp.id, parentId: grp.parentId, title: grp.name, checked: this.form.groups.indexOf(grp.id) != -1, expand: true, children: [] };
            this.initTreeData(node.children, res.data.groups, node.id)
            grpTree.push(node);
          }
          this.$set(this, 'groups', grpTree);
        }
      })
    },
    initTreeData: function (nodes, list, parent) {
      let subs = list.filter(p => p.parentId == parent);
      for (let grp of subs) {
        // let node = Object.assign(sub, {expand: true, children: [], title: sub.name, checked: this.form.groups.indexOf(sub.id) != -1});
        let node = { id: grp.id, parentId: grp.parentId, title: grp.name, checked: this.form.groups.indexOf(grp.id) != -1, expand: true, children: [] };
        this.initTreeData(node.children, list, grp.id);
        nodes.push(node);
      }
    },
    ok: async function () {
      let result = await this.$refs.form.validate();
      if (!result) {
        return;
      }
      let userGroups = [];
      let chkNodes = this.$refs.groupTree.getCheckedNodes();
      if (chkNodes.length == 0) {
        this.$Message.warning('请选择管理范围');
        return;
      } else {
        for (let cn of chkNodes) {
          userGroups.push(cn.id);
        }
      }
      let userNodes = this.$refs.userTable.getCheckboxRecords();
      let userIds = userNodes.map(p => p.id);
      if (userIds.length == 0) {
        this.$Message.warning('请选择人员范围');
        return;
      }
      this.form.groups = userGroups;
      this.form.users = userIds;
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/Maintance/SaveRemoteOrgan`, this.form).then(res => {
        this.loading = false;
        if (res.code !== 0) {
          // this.$Message.error(res.data);
          return;
        }
        this.$Message.info('保存成功');
        setTimeout(() => {
          this.showModal = false;
          this.$emit('saved', {});
        }, 800);
      })
    },
    cancel() {
      this.showModal = false;
    },
  }
}
</script>
<style scoped>
.edit-container {
  display: flex;
}
.form-area {
  /* border: solid 1px red; */
  width: 400px;
  /* height: 50px; */
  flex: auto;
}
.tree-area {
  border: solid 1px #dcdee2;
  border-radius: 6px;
  margin-left: 20px;
  width: 300px;
  flex: none;
  height: 600px;
  display: flex;
  flex-direction: column;
}
.tree-header {
  height: 40px;
  flex: none;
  border-bottom: solid 1px #dcdee2;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 23px;
}
.tree-content {
  height: 40px;
  flex: auto;
  overflow: auto;
  padding: 0 10px;
}
</style>
<template>
<div class="module-container">
  <div class="sys-work-area">
    <div class="form-area">
      <Form ref="form" class="form-data" :model="form" :label-width="120">
        <FormItem prop="paramId" label="是否启用工单">
          <i-switch v-model="form.enableSysWorkOrder" size="large" true-color="green" false-color="red">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
        </FormItem>
        <FormItem prop="limitTimeGot" label="">
          <span class="setting-header">工单限时配置</span>
        </FormItem>
        <FormItem prop="limitTimeLevel1" label="普通故障限时">
          <Input type="number" v-model="form.limitTimeLevel1" number><span slot="append">小时，为0表示不限时</span></Input>
        </FormItem>
        <FormItem prop="limitTimeLevel2" label="一般故障限时">
          <Input type="number" v-model="form.limitTimeLevel2" number><span slot="append">小时，为0表示不限时</span></Input>
        </FormItem>
        <FormItem prop="limitTimeLevel3" label="重大故障限时">
          <Input type="number" v-model="form.limitTimeLevel3" number><span slot="append">小时，为0表示不限时</span></Input>
        </FormItem>
        <FormItem prop="limitTimeLevel3" label="特殊故障时">
          由现场情况判定及管理人员按实际情况决定
        </FormItem>
        <FormItem prop="limitTimeGot" label="">
          <span class="setting-header">步骤限时配置</span>
        </FormItem>
        <FormItem prop="limitTimeGot" label="接警限时">
          <Input type="number" v-model="form.limitTimeGot" number><span slot="append">分钟，为0表示不限时</span></Input>
        </FormItem>
        <FormItem prop="limitTimeArrive" label="到达现场限时">
          <Input type="number" v-model="form.limitTimeArrive" number><span slot="append">分钟，为0表示不限时</span></Input>
        </FormItem>
        <FormItem prop="limitTimeCheck" label="故障判定限时">
          <Input type="number" v-model="form.limitTimeCheck" number><span slot="append">分钟，为0表示不限时</span></Input>
        </FormItem>
        <FormItem prop="limitTimeRemote" label="三遥厂家限时">
          <Input type="number" v-model="form.limitTimeRemote" number><span slot="append">分钟，为0表示不限时</span></Input>
        </FormItem>
        <FormItem prop="limitTimeAdmin" label="管理审批限时">
          <Input type="number" v-model="form.limitTimeAdmin" number><span slot="append">分钟，为0表示不限时</span></Input>
        </FormItem>
        <FormItem prop="limitTimeGot" label="">
          <span class="setting-header">工单通知配置</span>
        </FormItem>
        <FormItem prop="notifyMessage" label="短信通知">
          <i-switch v-model="form.notifyMessage" size="large" true-color="green" false-color="red">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
        </FormItem>
        <FormItem prop="notifyMessageDelay" label="短信延时时间">
          <Input type="number" v-model="form.notifyMessageDelay" number><span slot="append">分钟，此时间内未处理，即发短信通知</span></Input>
        </FormItem>
        <FormItem prop="notifyPhone" label="电话通知">
          <i-switch v-model="form.notifyPhone" :disabled="!form.notifyMessage" size="large" true-color="green" false-color="red">
            <span slot="open">启用</span>
            <span slot="close">禁用</span>
          </i-switch>
          启用短信通知后才有效
        </FormItem>
        <FormItem prop="notifyPhoneDelay" label="电话延时时间">
          <Input type="number" v-model="form.notifyPhoneDelay" number><span slot="append">分，短信通知后，此时间内未处理即通知</span></Input>
        </FormItem>
      </Form>
    </div>
    <div class="form-tools" v-if="funCodes('wcoe')">
      <Button type="primary" long :loading="loading" @click="saveSetting">保存工单配置</Button>
    </div>
  </div>
  <div class="level-item-content">
    <div class="alarm-type-header">启用工单的配电柜报警类型：</div>
    <div class="alarm-type-content">
      <Checkbox v-for="item in stationAlarmTypes" :key="item.code" class="chk-item" v-model="item.check">{{item.name}}</Checkbox>
    </div>
  </div>
  <div class="level-item-content">
    <div class="alarm-type-header">启用工单的单灯报警类型：</div>
    <div class="alarm-type-content">
      <Checkbox v-for="item in lightAlarmTypes" :key="item.code" class="chk-item" v-model="item.check">{{item.name}}</Checkbox>
    </div>
  </div>
  <!-- <div class="level-item-content">
    <div class="alarm-type-header">自动发起工单的报警类型：</div>
    <div class="alarm-type-content">
      <div class="alarm-type-list">
        <Checkbox v-for="item in stationAlarmTypes" :key="item.code" class="chk-item" label="全选" @on-change="chkAll10($event, list)">{{item.name}}</Checkbox>
      </div>
      <div class="alarm-type-list">

      </div>
    </div>
  </div> -->
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'WorkOrderConfigIndex',
  components: {
    // CommonTree,
  },
  data() {
    return {
      flows: [],
      fields: [],
      form: {
        enableSysWorkOrder: false,
        stationAlarmTypes: [],
        lightAlarmTypes: [],
        limitTimeGot: 30,
        limitTimeArrive: 80,
        limitTimeCheck: 60,
        limitTimeRemote: 60,
        limitTimeAdmin: 60,
        limitTimeLevel1: 6,
        limitTimeLevel2: 12,
        limitTimeLevel3: 72,
        notifyMessage: false,
        notifyMessageDelay: 5,
        notifyPhone: false,
        notifyPhoneDelay: 10,
      },
      chksLevel10: [],
      loading: false,
      alarmTypes: {},
      alarmGroupList: {},
      stationAlarmTypes: [],
      lightAlarmTypes: [],
    }
  },
  watch: {
  },
  mounted: function () {
    this.getStationAlarmTypes();
    this.getLightAlarmTypes();
    this.getSetting();
  },
  destroyed: function () {
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('auth', ['user', 'ops']),
    ...mapState('group', ['selectedNode', 'groupLoaded']),
    // ...mapState('common', ['alarmTypes', 'alarmGroupList']),
  },
  methods: {
    getStationAlarmTypes: function(){
      this.$axios.post(`//${this.domains.trans}/station/alarm/QueryStationTypes`, {}).then(res => {
        if(res.code != 0)return;
        res.data.map(p => {
          p.check = this.form.stationAlarmTypes.indexOf(p.code) != -1;
        })
        this.$set(this, 'stationAlarmTypes', res.data);
      });
    },
    getLightAlarmTypes: function(){
      this.$axios.post(`//${this.domains.trans}/station/alarm/QueryLightTypes`, {}).then(res => {
        if(res.code != 0)return;
        res.data.map(p => {
          p.check = this.form.lightAlarmTypes.indexOf(p.code) != -1;
        })
        this.$set(this, 'lightAlarmTypes', res.data);
      });
    },
    chkAll10: function(chk, list){
      // console.log('get chks all', chk, list)
      for(let item of list){
        let name = this.alarmTypes[item];
        let idx = this.chksLevel10.indexOf(name);
        if(chk && idx == -1){
          this.chksLevel10.push(name);
        }else if(!chk && idx != -1){
          this.chksLevel10.splice(idx, 1);
        }
      }
    },
    getSetting: function () {
      this.$axios.post(`//${this.domains.trans}/station/Maintance/QueryWorkSetting`, {}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.form.enableSysWorkOrder = res.data.enableSysWorkOrder;
          this.form.stationAlarmTypes = res.data.stationAlarmTypes;
          this.form.lightAlarmTypes = res.data.lightAlarmTypes;
          this.form.limitTimeGot = res.data.limitTimeGot;
          this.form.limitTimeArrive = res.data.limitTimeArrive;
          this.form.limitTimeCheck = res.data.limitTimeCheck;
          this.form.limitTimeRemote = res.data.limitTimeRemote;
          this.form.limitTimeAdmin = res.data.limitTimeAdmin;
          this.form.limitTimeLevel1 = res.data.limitTimeLevel1;
          this.form.limitTimeLevel2 = res.data.limitTimeLevel2;
          this.form.limitTimeLevel3 = res.data.limitTimeLevel3;
          this.form.notifyMessage = res.data.notifyMessage;
          this.form.notifyMessageDelay = res.data.notifyMessageDelay;
          this.form.notifyPhone = res.data.notifyPhone;
          this.form.notifyPhoneDelay = res.data.notifyPhoneDelay;
          for(let item of this.stationAlarmTypes){
            if(this.form.stationAlarmTypes.indexOf(item.code) != -1)item.check = true;
          }
          for(let item of this.lightAlarmTypes){
            if(this.form.lightAlarmTypes.indexOf(item.code) != -1)item.check = true;
          }
        }
      });
    },
    getKeyByName: function(names){
      let keys = [];
      for(let key in this.alarmTypes){
        if(names.indexOf(this.alarmTypes[key]) != -1){
          keys.push(key);
        }
      }
      return keys;
    },
    saveSetting: function () {
      let schks = [], lchks = [];
      for(let item of this.stationAlarmTypes){
        if(item.check)schks.push(item.code);
      }
      for(let item of this.lightAlarmTypes){
        if(item.check)lchks.push(item.code);
      }
      this.form.stationAlarmTypes = schks;
      this.form.lightAlarmTypes = lchks;
      if(this.form.enableSysWorkOrder){
        if(this.form.stationAlarmTypes.length == 0 && this.form.lightAlarmTypes.length == 0){
          this.$Message.info('请至少选择一个启用工单的报警类型');
          return;
        }
      }
      this.loading = true;
      this.$axios.post(`//${this.domains.trans}/station/Maintance/SaveWorkSetting`, this.form).then(res => {
        this.loading = false;
        if (res.code != 0) {
          // this.$Message.error(res.data);
        } else {
          this.$Message.info('保存成功');
        }
      });
    },
  }
}
</script>
<style scoped>
.setting-header{
  font-size: 30px;
  font-weight: bolder;
}
.module-container {
  /* border: solid 1px red; */
  display: flex;
  height: 100%;
  align-items: stretch;
  position: relative;
}
.alarm-type-header{
  height: 50px;
  flex: none;
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 25px;
  border-bottom: solid 1px rgba(219, 175, 175, 0.568);
}
.alarm-type-content{
  /* border: solid 1px red; */
  height: 500px;
  flex: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: auto;
}
.level-item-content{
  border: solid 1px rgba(219, 175, 175, 0.568);
  /* border: solid 1px red; */
  width: 500px;
  flex: auto;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  overflow-y: auto;
}
.sys-work-area {
  border: solid 1px rgba(219, 175, 175, 0.568);
  margin-right: 20px;
  width: 500px;
  margin: 0;
  flex: none;
  display: flex;
  flex-direction: column;
}
.form-data{
  /* border: solid 1px red; */
}
.form-area {
  /* border: solid 1px red; */
  /* margin: 20px 20px; */
  height: 700px;
  padding-right: 20px;
  flex: auto;
  overflow-y: auto;
}
.form-tools{
  padding: 0 50px;
  height: 50px;
  flex: none;
}
.chk-item{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin-bottom: 5px;
}
.alarm-type{
  /* border: solid 1px rgba(219, 175, 175, 0.568); */
  margin: 5px;
  padding: 5px;
}
.alarm-type-name{
  padding: 5px 0 1px 0;
  border-bottom: solid 1px rgba(219, 175, 175, 0.568);
  margin-bottom: 10px;
}
</style>
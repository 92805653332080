<template>
<div class="flow-container">
  <!-- <div class="flow-menu">
    
  </div> -->
  <div class="flow-content" ref="table">
    <vxe-table border show-overflow show-header-overflow :data="organs" :height="tabHeight" :row-config="{isHover: true}">
      <vxe-column field="name" title="名称" width="250"></vxe-column>
      <!-- <vxe-column title="是否管理单位" width="120">
        <template #default="params">
          {{params.row.isManage ? '是' : '否'}}
        </template>
      </vxe-column>
      <vxe-column title="是否管养单位" width="120">
        <template #default="params">
          {{params.row.isMaintance ? '是' : '否'}}
        </template>
      </vxe-column> -->
      <vxe-column field="linkman" title="联系人" width="150"></vxe-column>
      <vxe-column field="mobile" title="联系方式" width="120"></vxe-column>
      <vxe-column field="groupNames" title="管理范围"></vxe-column>
      <!-- <vxe-column field="userNames" title="人员范围"></vxe-column> -->
      <vxe-column field="mainUserName" title="主要值班人" width="150"></vxe-column>
      <vxe-column field="bakUserName" title="候补值班人" width="150"></vxe-column>
      <vxe-column width="250" title="操作" fixed="right">
        <template #header>
          <Button v-if="funCodes('woma')" size="small" type="primary" @click="handleAdd" style="margin-right: 5px">新增</Button>
          <Button v-if="funCodes('womv')" size="small" type="primary" @click="getList">刷新</Button>
        </template>
        <template #default="params">
          <Button v-if="funCodes('wome')" size="small" type="success" @click="handleDuty(params)" style="margin-right: 5px">设置值班人员</Button>
          <Button v-if="funCodes('wome')" size="small" type="info" @click="handleEdit(params)" style="margin-right: 5px">编辑</Button>
          <Button v-if="funCodes('womd')" size="small" type="error" @click="handleDelete(params)" style="margin-right: 5px">删除</Button>
        </template>
      </vxe-column>
    </vxe-table>
  </div>
  <ModalOrganEdit v-model="showEditModel" :item="editItem" @saved="getList"/>
  <ModalDutyEdit v-model="showDutyModel" :item="editItem" @saved="getList"/>
</div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import ModalOrganEdit from './ModalOrganEdit'
import ModalDutyEdit from './ModalDutyEdit'
export default {
  name: 'WorkFlowDefineIndex',
  components: {
    ModalOrganEdit,
    ModalDutyEdit,
  },
  props: {
    sys: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      showEditModel: false,
      showDutyModel: false,
      tabHeight:500,
      organs: [],
      flowId: 0,
      editItem: {},
      forms: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['funCodes']),
    ...mapState('cmd', ['windowOnResize']),
  },
  watch: {
    windowOnResize(){
      this.setTableHeight();
    },
  },
  mounted: function(){
    setTimeout(this.setTableHeight, 200);
    // this.getForms();
    this.getList();
  },
  methods: {
    setTableHeight: function(){
      this.tabHeight = this.$refs.table.clientHeight - 6;
    },
    handleAdd: function(){
      this.editItem = {isAdd: true, data: {}};
      this.showEditModel = true;
    },
    handleEdit: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showEditModel = true;
    },
    handleDuty: function(params){
      this.editItem = {isAdd: false, data: params.row};
      this.showDutyModel = true;
    },
    handleDelete: function(params){
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除单位 ${params.row.name} 吗?`,
        onOk: async () => {
          this.$axios.post(`//${this.domains.trans}/station/Maintance/DeleteManageOrgan`, {id: params.row.id}).then(res => {
            if(res.code != 0){
              // this.$Message.error(res.data);
            }else{
              this.$Message.info("删除成功");
              this.getList();
            }
          });
        }
      });
    },
    getList: function(){
      this.$axios.post(`//${this.domains.trans}/station/Maintance/QueryManageOrgan`, {}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'organs', res.data);
        }
      });
    },
  }
}
</script>
<style scoped>
.flow-container{
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.flow-menu{
  height: 40px;
  margin-bottom: 5px;
  flex: none;
  border-radius: 6px;
  display: flex;
  padding: 3px;
}
.flow-content{
  height: 500px;
  flex: auto;
  padding: 3px;
  border-radius: 6px;
}
</style>
